

import React from 'react'
import { useNavigate } from 'react-router-dom';


const Record = (props) => {
    let navigate = useNavigate();

    const onBack = (e) => {
        console.log("dfghjk")
        navigate(`${e}`)
    }
  return (
 
     <div className='japurLudoNav realludokingsize'>
          <div className='container'>
          <div class="row nav-top auto"><div class="col-12 ddavc text-center">
          <span class=" " style={{ fontSize: '40px', fontWeight: 'normal' }} onClick={() => onBack('/')}>&#x2039;</span>

         <div className='w-100 tf-18 pl-1 tfw-5 tf-18'>FastParity Record</div>
            </div><div class="col-12" id="mnvbt"><div class="row mr-0 tf-14"><div class="col-4  xtl">Period</div><div class="col-2">Price</div><div class="col-2">Result</div><div class="col-4 pa-0 xtr">Open Time</div></div></div></div>
          <div class="row"><div class="col-12 bg-white" id="dtaod">
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041788</div>
	<div class="col-2">58693</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:54:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041787</div>
	<div class="col-2">54978</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:53:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041786</div>
	<div class="col-2">64811</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:53:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041785</div>
	<div class="col-2">52197</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:52:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041784</div>
	<div class="col-2">66156</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:52:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041783</div>
	<div class="col-2">62058</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:51:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041782</div>
	<div class="col-2">64942</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:51:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041781</div>
	<div class="col-2">55336</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:50:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041780</div>
	<div class="col-2">59059</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:50:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041779</div>
	<div class="col-2">57425</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:49:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041778</div>
	<div class="col-2">55137</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:49:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041777</div>
	<div class="col-2">56967</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:48:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041776</div>
	<div class="col-2">52123</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:48:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041775</div>
	<div class="col-2">50031</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:47:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041774</div>
	<div class="col-2">61568</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:47:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041773</div>
	<div class="col-2">66487</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:46:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041772</div>
	<div class="col-2">60102</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:46:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041771</div>
	<div class="col-2">55262</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:45:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041770</div>
	<div class="col-2">69677</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:45:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041769</div>
	<div class="col-2">64239</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:44:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041768</div>
	<div class="col-2">57025</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:44:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041767</div>
	<div class="col-2">60167</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:43:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041766</div>
	<div class="col-2">57988</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:43:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041765</div>
	<div class="col-2">58301</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:42:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041764</div>
	<div class="col-2">62233</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:42:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041763</div>
	<div class="col-2">54064</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:41:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041762</div>
	<div class="col-2">56068</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:41:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041761</div>
	<div class="col-2">55534</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:40:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041760</div>
	<div class="col-2">67835</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:40:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041759</div>
	<div class="col-2">69828</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:39:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041758</div>
	<div class="col-2">62418</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:39:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041757</div>
	<div class="col-2">57796</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:38:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041756</div>
	<div class="col-2">65448</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:38:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041755</div>
	<div class="col-2">50646</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:37:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041754</div>
	<div class="col-2">59208</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:37:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041753</div>
	<div class="col-2">51008</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:36:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041752</div>
	<div class="col-2">61090</div>
	<div class="col-2"><div class="RS"><div class="vil"></div><div class="tpr">0</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:36:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041751</div>
	<div class="col-2">53546</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:35:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041750</div>
	<div class="col-2">50654</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:35:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041749</div>
	<div class="col-2">67958</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:34:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041748</div>
	<div class="col-2">69063</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:34:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041747</div>
	<div class="col-2">69704</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:33:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041746</div>
	<div class="col-2">50116</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:33:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041745</div>
	<div class="col-2">50843</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:32:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041744</div>
	<div class="col-2">68346</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:32:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041743</div>
	<div class="col-2">69737</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:31:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041742</div>
	<div class="col-2">65438</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:31:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041741</div>
	<div class="col-2">64202</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:30:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041740</div>
	<div class="col-2">57984</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:30:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041739</div>
	<div class="col-2">66180</div>
	<div class="col-2"><div class="RS"><div class="vil"></div><div class="tpr">0</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:29:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041738</div>
	<div class="col-2">65135</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:29:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041737</div>
	<div class="col-2">69262</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:28:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041736</div>
	<div class="col-2">54054</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:28:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041735</div>
	<div class="col-2">66415</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:27:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041734</div>
	<div class="col-2">56294</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:27:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041733</div>
	<div class="col-2">56295</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:26:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041732</div>
	<div class="col-2">63232</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:26:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041731</div>
	<div class="col-2">69748</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:25:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041730</div>
	<div class="col-2">56885</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:25:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041729</div>
	<div class="col-2">55521</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:24:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041728</div>
	<div class="col-2">65822</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:24:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041727</div>
	<div class="col-2">62698</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:23:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041726</div>
	<div class="col-2">51348</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:23:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041725</div>
	<div class="col-2">59838</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:22:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041724</div>
	<div class="col-2">68073</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:22:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041723</div>
	<div class="col-2">69130</div>
	<div class="col-2"><div class="RS"><div class="vil"></div><div class="tpr">0</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:21:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041722</div>
	<div class="col-2">53973</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:21:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041721</div>
	<div class="col-2">54144</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:20:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041720</div>
	<div class="col-2">64104</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:20:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041719</div>
	<div class="col-2">54771</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:19:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041718</div>
	<div class="col-2">57047</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:19:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041717</div>
	<div class="col-2">59617</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:18:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041716</div>
	<div class="col-2">66526</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:18:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041715</div>
	<div class="col-2">66604</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:17:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041714</div>
	<div class="col-2">65739</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:17:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041713</div>
	<div class="col-2">67573</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:16:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041712</div>
	<div class="col-2">67842</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:16:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041711</div>
	<div class="col-2">62627</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:15:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041710</div>
	<div class="col-2">62608</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:15:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041709</div>
	<div class="col-2">62634</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:14:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041708</div>
	<div class="col-2">62728</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:14:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041707</div>
	<div class="col-2">67577</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:13:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041706</div>
	<div class="col-2">56915</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:13:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041705</div>
	<div class="col-2">56087</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:12:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041704</div>
	<div class="col-2">58406</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:12:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041703</div>
	<div class="col-2">67347</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:11:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041702</div>
	<div class="col-2">57341</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:11:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041701</div>
	<div class="col-2">65342</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:10:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041700</div>
	<div class="col-2">64148</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:10:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041699</div>
	<div class="col-2">65049</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:09:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041698</div>
	<div class="col-2">61568</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:09:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041697</div>
	<div class="col-2">64803</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:08:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041696</div>
	<div class="col-2">64603</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:08:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041695</div>
	<div class="col-2">60561</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:07:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041694</div>
	<div class="col-2">57536</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:07:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041693</div>
	<div class="col-2">59871</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">1</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:06:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041692</div>
	<div class="col-2">69265</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:06:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041691</div>
	<div class="col-2">67068</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:05:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041690</div>
	<div class="col-2">65763</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:05:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041689</div>
	<div class="col-2">57667</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:04:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041688</div>
	<div class="col-2">59699</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:04:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041687</div>
	<div class="col-2">63223</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:03:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041686</div>
	<div class="col-2">60347</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:03:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041685</div>
	<div class="col-2">50446</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:02:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041684</div>
	<div class="col-2">54606</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:02:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041683</div>
	<div class="col-2">55820</div>
	<div class="col-2"><div class="RS"><div class="vil"></div><div class="tpr">0</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:01:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041682</div>
	<div class="col-2">62104</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:01:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041681</div>
	<div class="col-2">67263</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:00:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041680</div>
	<div class="col-2">64823</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 14:00:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041679</div>
	<div class="col-2">57116</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:59:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041678</div>
	<div class="col-2">63308</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">8</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:59:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041677</div>
	<div class="col-2">65615</div>
	<div class="col-2"><div class="GS"><div class="vil"></div><div class="tpr">5</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:58:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041676</div>
	<div class="col-2">64323</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">3</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:58:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041675</div>
	<div class="col-2">61089</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:57:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041674</div>
	<div class="col-2">58754</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">4</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:57:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041673</div>
	<div class="col-2">54639</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:56:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041672</div>
	<div class="col-2">61242</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">2</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:56:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041671</div>
	<div class="col-2">62946</div>
	<div class="col-2"><div class="RS"><div class=""></div><div class="tpr">6</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:55:30</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041670</div>
	<div class="col-2">69957</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">7</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:55:00</div>
</div>
<div class="row mt-2 mb-2 lih-32">
	<div class="col-4 xtl">202407041669</div>
	<div class="col-2">50339</div>
	<div class="col-2"><div class="GS"><div class=""></div><div class="tpr">9</div></div></div>
	<div class="col-4 xtr tfwr">04/07 13:54:30</div>
</div></div></div>

    </div>
   </div>
   
  )
}

export default Record
