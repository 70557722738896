
import React from 'react'


const Dice = () => {
  return (
    <section class="container-fluid">
      <div class="row mcas">
          <div class="col-md-6 col-lg-4 main">
              <div class="row" id="warea">
                  <div class="col-12 nav-top">
                      <div class="row">
                          <div class="col-2 xtl"><span class="nav-back wt" onclick="window.location.href='#/'"></span>
                          </div>
                          <div class="col-8 tfw-5 tf-18">DICE</div>
                          <div class="col-2 xtr mcpl" >Rule</div>
                      </div>
                  </div>
                  <div class="col-12 gmxcfm">
                      <div class="row mt-2 tfwr pt-2">
                          <div class="col-6 xtl">
                              <div class="tf-16 mb-2 tfcdg">Period</div>
                              <div class="tfcdb tf-24 tfw-5 period" id="cpd">65</div>
                          </div>
                          <div class="col-6 xtr">
                              <div class="tf-16 mb-2 tfcdg">Count Down</div>
                              <div class="cd" id="demo" onclick="CRN30SP()">

                              </div>
                              <div id="cdx"></div>
                          </div>
                      </div>
                      <div class="row mb-2 dsbd">
                          <div class="col-6 dsbr">
                              <div class="tf-14">Less than</div>
                              <div class="tf-20 dsn" id="dsnL">95</div>
                          </div>
                          <div class="col-6">
                              <div class="tf-14">Multiplier</div>
                              <div class="tf-20" id="pxm">98</div>
                          </div>
                      </div>

                      <div class="row tf-10 mr-0 mt-4">
                          <div class="col-12 mt-2 pa-0"><input type="range" v-model="userdetails.slide" class="slider dns"
                                  min="4" max="92"/>
                              <div class="slidPro" id="slidPro" ></div>
                          </div>
                          <div class="col-12 xtc pa-0 pl-1 pr-1">
                              <div class="mt-2 dflx wtj0ep"><span>1</span> <span>|</span><span>25</span>
                                  <span>|</span><span>50</span> <span>|</span> <span>75</span>
                                  <span>|</span><span>99</span>
                              </div>
                              <div id="cdx"></div>
                          </div>
                      </div>

                      <div class="row mr-0 mt-3">
                          <div class="col-12 pa-0">
                              <div class="jnum dn disabled" id="jndn">
                                  <div class="tfw-6 tf-16" >Less than <span id="dsn">65</span></div>
                              </div>
                          </div>
                      </div>

                  </div>
                  <div class="col-12">
                      <div class="row" id="nfrm" style={{display:'none'}}>
                          <div class="col-12 pa-0 odbox bslidein">
                              <div class="betclass('xsel')" id="nxsel">Less than 98</div>
                              <div class="row bfard">
                                  <div class="col-6 xtl tfcdb tf-20 tffss">₹<span class="tf-28" id="u_bal">65</span>
                                  </div>
                                  <div class="col-6 pl-0 pr-1 xtr pt-1">
                                      <div class="rc-wal" onclick="window.location.href='#/recharge'">Recharge</div>
                                  </div>
                              </div>
                              <div class="row mr-0 pa-10">
                                  <div class="col-12 xtl">Contract Money</div>
                                  <div class="col-12 tf-16 xtl" id="factorlist">
                                      <div class="betclass('cont-amt selected')" id="10" >
                                          10</div>
                                      <div class="betclass('cont-amt')" id="100" >100
                                      </div>
                                      <div class="betclass('cont-amt')" id="1000" >1000
                                      </div>
                                      <div class="betclass('cont-amt')" id="10000" >
                                          10000</div>
                                  </div>
                              </div>
                              <div class="row mr-0 pa-10">
                                  <div class="col-12 xtl">
                                      <div class="silb mr-2">Number</div>
                                      <div class="row">
                                          <div class="col-5 xtl pr-0"><span class="xpan" id="xm5"
                                                  >-5</span><span class="xpan" id="xm"
                                               >-1</span></div>
                                          <div class="col-2 xtc"><span class="xnum" id="xn">65</span>
                                          </div>
                                          <div class="col-5 xtr pl-0"><span class="xpan" id="xp"
                                                  >+1</span><span class="xpan" id="xp5"
                                                 >+5</span></div>
                                      </div>
                                  </div>
                                  <div class="col-12 mt-2 tf-16 xtl">Total contract money is <span id="tca">65</span>
                                  </div>
                              </div>
                              <div class="row mr-0 mb-2">
                                  <div class="col-12 xtr">
                                      <div class="betclass('btn-con newod')" id="nod" >Confirm
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 betfbg" id="blackscreen"  style={{display:'none'}}></div>
                  <div class="col-12 mt-2">
                      <div class="row tffm h264">
                          <div class="col-12 tfw-7 tabin active" id="rcdv">Record</div>
                          <div class="col-12">
                              <div class="row tf-16 mr-0" id="xrecd">
                                  <div class="col-8 mt-2 mb-2 pb-2 pl-0 xtl tf-18 tfcdb">Dice Record(s)</div>
                                  <div class="col-4 mt-2 mb-2 pb-2 pr-0 tf-14 xtr"><span class="mcpl tfcdg"
                                          onclick="window.location.href='#/record?server=Dice'">more &gt;</span>
                                  </div>
                                  <div class="col-12">
                                      <div class="row record">


                                          <div class="rcd-pillar mb-2" v-for="row in resultrec" key="row.id">
                                              <div class="NS">
                                                  <div class="tpr">65</div>
                                              </div>
                                              <div class="rcd-per">65</div>
                                          </div>

                                          <div class="rcd-pillar mb-2" id="upcp">
                                              <div class="per-rcd dn upn"><b>?</b></div>
                                              <div class="rcd-per">65</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row mr-0" id="capr"></div>
                          </div>
                      </div>
                      <div class="row mr-0" id="lfprd"></div>
                      <div class="row mt-2">
                          <div class="col-6 tfw-6 tffm tabin active" id="every" >Everyone's
                              Order
                          </div>
                          <div class="col-6 tfw-6 tffm tabin" id="my">My Order</div>
                          <div class="col-12" id="myorder" style={{display:'none'}}>
                              <div class="row plr-10 pb-2">
                                  <div class="col-12 pb-3 pt-2 tf-14 dflx">
                                      <div class="wd-100 xtl">Period</div>
                                      <div class="wd-36">Select</div>
                                      <div class="wd-46">Point</div>
                                      <div class="wd-36">Result</div>
                                      <div class="wd-80 xtr">Amount</div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12" id="mywod"></div>
                                  <div class="col-12" id="myod">
                                      <div class="row plr-10">
                                          <div class="col-12 lih-32 pt-2 pb-2 dflx" v-for="rows in betrec" key="rows.id"
                                            rowsrec="rows">
                                              <div class="wd-100 xtl">65</div>
                                              <div class="wd-36"><span class="NS">65</span></div>
                                              <div class="wd-46">32</div>
                                              <div class="wd-36" v-html="getele(rows.number)">

                                              </div>
                                              <div class="wd-80 xtr" v-html="betamount(rows.amount, rows.ans, rows.res)">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12">
                                      <div class="m-order" onclick="window.location.href='#/orderrecord?server=Dice'">
                                          more &gt;</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-12" id="everyoneorder">
                              <div class="row">
                                  <div class="col-4 tf-14 pt-2 xtl">Period</div>
                                  <div class="col-3 tf-14 pt-2">User</div>
                                  <div class="col-2 tf-14 pt-2">Select</div>
                                  <div class="col-3 tf-14 pt-2 xtr">Point</div>
                              </div>
                              <div class="row">
                                  <marquee direction="down" id="everyone" scrollamount="3">
                                      <div class="col-12" id="deod">
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}} >
                                              <div class="col-4 xtl" >25</div>
                                              <div class="col-3 cgray">***086</div>
                                              <div class="col-2 "><span class="NS">74</span></div>
                                              <div class="col-3 xtr">₹1000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***323</div>
                                              <div class="col-2 "><span class="NS">92</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***749</div>
                                              <div class="col-2 "><span class="NS">46</span></div>
                                              <div class="col-3 xtr">₹50</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***167</div>
                                              <div class="col-2 "><span class="NS">4</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***574</div>
                                              <div class="col-2 "><span class="NS">63</span></div>
                                              <div class="col-3 xtr">₹20</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***240</div>
                                              <div class="col-2 "><span class="NS">43</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***826</div>
                                              <div class="col-2 "><span class="NS">78</span></div>
                                              <div class="col-3 xtr">₹50000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***178</div>
                                              <div class="col-2 "><span class="NS">63</span></div>
                                              <div class="col-3 xtr">₹20</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***771</div>
                                              <div class="col-2 "><span class="NS">68</span></div>
                                              <div class="col-3 xtr">₹10000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***015</div>
                                              <div class="col-2 "><span class="NS">75</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***367</div>
                                              <div class="col-2 "><span class="NS">44</span></div>
                                              <div class="col-3 xtr">₹500</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***261</div>
                                              <div class="col-2 "><span class="NS">14</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***687</div>
                                              <div class="col-2 "><span class="NS">34</span></div>
                                              <div class="col-3 xtr">₹500</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***374</div>
                                              <div class="col-2 "><span class="NS">24</span></div>
                                              <div class="col-3 xtr">₹100</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***363</div>
                                              <div class="col-2 "><span class="NS">26</span></div>
                                              <div class="col-3 xtr">₹10000</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***349</div>
                                              <div class="col-2 "><span class="NS">17</span></div>
                                              <div class="col-3 xtr">₹20</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***374</div>
                                              <div class="col-2 "><span class="NS">51</span></div>
                                              <div class="col-3 xtr">₹2000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***506</div>
                                              <div class="col-2 "><span class="NS">6</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***729</div>
                                              <div class="col-2 "><span class="NS">80</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***395</div>
                                              <div class="col-2 "><span class="NS">74</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***785</div>
                                              <div class="col-2 "><span class="NS">14</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***745</div>
                                              <div class="col-2 "><span class="NS">55</span></div>
                                              <div class="col-3 xtr">₹10000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***339</div>
                                              <div class="col-2 "><span class="NS">25</span></div>
                                              <div class="col-3 xtr">₹5000</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***924</div>
                                              <div class="col-2 "><span class="NS">24</span></div>
                                              <div class="col-3 xtr">₹100</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***643</div>
                                              <div class="col-2 "><span class="NS">29</span></div>
                                              <div class="col-3 xtr">₹50000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***001</div>
                                              <div class="col-2 "><span class="NS">50</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***642</div>
                                              <div class="col-2 "><span class="NS">8</span></div>
                                              <div class="col-3 xtr">₹1000</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***775</div>
                                              <div class="col-2 "><span class="NS">32</span></div>
                                              <div class="col-3 xtr">₹300</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***830</div>
                                              <div class="col-2 "><span class="NS">48</span></div>
                                              <div class="col-3 xtr">₹20</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***462</div>
                                              <div class="col-2 "><span class="NS">64</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***461</div>
                                              <div class="col-2 "><span class="NS">42</span></div>
                                              <div class="col-3 xtr">₹1000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***253</div>
                                              <div class="col-2 "><span class="NS">52</span></div>
                                              <div class="col-3 xtr">₹5000</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***012</div>
                                              <div class="col-2 "><span class="NS">38</span></div>
                                              <div class="col-3 xtr">₹500</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***681</div>
                                              <div class="col-2 "><span class="NS">29</span></div>
                                              <div class="col-3 xtr">₹4000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***118</div>
                                              <div class="col-2 "><span class="NS">20</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***613</div>
                                              <div class="col-2 "><span class="NS">6</span></div>
                                              <div class="col-3 xtr">₹20000</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***399</div>
                                              <div class="col-2 "><span class="NS">6</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***715</div>
                                              <div class="col-2 "><span class="NS">23</span></div>
                                              <div class="col-3 xtr">₹5000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***529</div>
                                              <div class="col-2 "><span class="NS">38</span></div>
                                              <div class="col-3 xtr">₹50</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***684</div>
                                              <div class="col-2 "><span class="NS">13</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***031</div>
                                              <div class="col-2 "><span class="NS">47</span></div>
                                              <div class="col-3 xtr">₹1000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***500</div>
                                              <div class="col-2 "><span class="NS">33</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***759</div>
                                              <div class="col-2 "><span class="NS">70</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***868</div>
                                              <div class="col-2 "><span class="NS">34</span></div>
                                              <div class="col-3 xtr">₹10</div>
                                          </div>
                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***648</div>
                                              <div class="col-2 "><span class="NS">34</span></div>
                                              <div class="col-3 xtr">₹500</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***062</div>
                                              <div class="col-2 "><span class="NS">65</span></div>
                                              <div class="col-3 xtr">₹10000</div>
                                          </div>

                                          <div class="row pt-2 pb-2 lih-32" style={{height:'48px'}}>
                                              <div class="col-4 xtl">66</div>
                                              <div class="col-3 cgray">***071</div>
                                              <div class="col-2 "><span class="NS">32</span></div>
                                              <div class="col-3 xtr">₹5000</div>
                                          </div>
                                      </div>
                                  </marquee>
                              </div>
                          </div>

                      </div>
                      <div class="row mr-0 mordm"></div>
                  </div>
                  <div class="col-12 betfbg" id="bfbg" onclick="clsbfrm()"></div>
                  <div class="col-12" id="ucheck"></div>
                  <div class="col-12" id="suc-fail">
                      <div class="col-12 conod"  id="rlink" style={{display:'none'}} >
                          <div class="row podfs fadein" id="smgid">
                              <div class="getrcl(result.res)">65</div>
                              <div class="col-12" v-if="result.res === 'success'">
                                <img class="winxIMG" src="../assets/includes/images/win.png" width="320"/>
                              </div>
                              <div class="col-12 pt-2 pb-2"><span class="getcl(result.number)" >24</span></div>
                              <div class="col-12">
                                  <div class="row tfcdb tf-16 xtl">
                                      <div class="col-4 pb-1">Period</div>
                                      <div class="col-8 pb-1 xtr">35</div>
                                      <div class="col-4">Price</div>
                                      <div class="col-8 xtr">21</div>
                                  </div>
                              </div>
                              <div class="col-12">
                                  <div class="row tfcdb selwnlbx xtl">
                                      <div class="col-4 pl-1 pb-1">Select</div>
                                      <div class="col-8 pr-1 xtr pb-1"><span class="resclass(result.ans)" >24</span></div>
                                      <div class="col-4 pl-1 pb-1">Point</div>
                                      <div class="col-8 pr-1 xtr pb-1 tf-16">35</div>
                                      <div class="col-4 pl-1 pb-1">Amount</div>
                                      <div class="col-8 pr-1 xtr pl-0 pb-1 tf-24 tfwr" v-html="betamount(result.amount, result.ans, result.res)">
                                          
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 mb-3">
                                  <div class="btn-main act">CLOSE</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 bbg" id="xrule" style={{display:'none'}}>
                      <div class="col-12 botfrm bslidein"style={{display:'block'}}>
                          <div class="tf-20 mt-3 mb-3"><span class="grlm">Dice Rule</span></div>
                          <div class="row mt-2 pb-2 tfcdb grh-60">
                              <div class="col-12 xtl tf-16">
                                  <div class="justify">1 minutes 1 issue, 50 seconds to order, 10 seconds to show the
                                      lottery result. It opens all day. The total number of trade is 1440 issues.
                                  </div>
                              </div>
                              <div class="col-12 tfs-b tf-14 xtc">
                                  <div class="row mr-0 tlh-36 tfwr">
                                      <div class="col-3 grth grbd-l grbd-b grbd-t">Select</div>
                                      <div class="col-6 grth grbd-l grbd-r grbd-b grbd-t">Result</div>
                                      <div class="col-3 grth grbd-r grbd-b grbd-t">You</div>
                                      <div class="col-3 xtc grbd-l grbd-b pt-3 pl-2"> &lt; N </div>
                                      <div class="col-9 grbd-l grbd-r grbd-b">
                                          <div class="row">
                                              <div class="col-8 grbd-b"> &lt; n </div>
                                              <div class="col-4 grbd-b grbd-l">Win</div>
                                              <div class="col-8">≥ n</div>
                                              <div class="col-4 grbd-l">Loose</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 fixn">
                                  <div class="btn-con cls" >I GOT IT</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row" id="odrea"></div>
              <div class="row" id="footer"></div>
              <div class="row" id="opffp"></div>
              <div class="row" id="anof">

              </div>
              <div class="row" id="clink" style={{display:'none'}}>
                  <div class="col-12 conod LR" >
                      <div class="ssmg fadein">
                          <div class="tf-14 pb-2" id="message"> </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  )
}

export default Dice;