// VideoModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import './VideoModal.css'; // Optional for custom styling
import guide from "../video/guide.mp4"

Modal.setAppElement('#root'); // Required by react-modal

const VideoModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="video-modal">
      <button onClick={openModal} className="open-modal-btn">
        Open Video
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <button onClick={closeModal} className="close-modal-btn">
            &times;
          </button>
        </div>
        <div className="modal-body">
          <video width="100%" controls>
            <source src={guide} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </div>
  );
};

export default VideoModal;
